<template>
    <b-container fluid>
        <b-row class="text-center mb-3">
            <b-col>
                <h1>Le massage liftant japonais du visage inspiré du Kobido</h1>
            </b-col>
        </b-row>
        <b-row align-v="center" class="ml-lg-5">
            <b-col xs="12" lg="6">
                <p>
                    <div class="d-none d-lg-block"><br /></div>
                    L’origine du Kobido remonte à 1472, et signifie « ancienne voie de la beauté ».<br />
                    A l’époque, il était exclusivement réservé aux geishas et à la famille impériale pour préserver
                    la beauté naturelle. <br />
                    <br />
                    Les bienfaits de ce massage sont nombreux. Il ralentit le vieillissement cutané, stimule la
                    circulation sanguine et lymphatique, diminue les rides, poches et cernes, tonifie, repulpe et
                    redonne un coup d’éclat. Il détend les muscles du visage et de la nuque et par conséquent
                    permet une profonde relaxation. <br />
                    <br />
                    Pour qui ? <br />
                    <br />
                    Pour toutes les femmes, mamans, futures mamans qui ont envie de prendre soin d’elles et
                    d’enlever ou diminuer toutes les tensions qu’elles peuvent accumuler.<br />
                    <br />
                    Mais aussi pour les hommes, papas et futurs papas. (sous certaines conditions).<br />
                    <br />
                    Je me déplace à votre domicile avec ma table de massage et tout le matériel nécessaire.
                    J’utilise de l’huile neutre, des manœuvres lentes et toniques, je fais un massage du cuir
                    chevelu... <br />
                    <br />
                    Le massage dure 1h mais prévoyez 1h30. Pensez à mettre une tenue confortable pour
                    profiter au maximum. <br />
                    <price :price="75" />
                </p>
            </b-col>
            <b-col xs="12" lg="6">
                <non-clickable-img url="kobido.jpg" />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Price from '../components/Price';
import NonClickableImg from '../components/NonClickableImg';

export default {
    name: 'Kobido',
    components: { Price, NonClickableImg },
};
</script>

<style scoped>
p {
    text-align: justify;
}
</style>